import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box, Button,
    FormControlLabel, Switch,
    Typography,
    useTheme
} from "@mui/material";
import useCustomTranslation from "../hooks/useCustomTranslation";
import React, {useEffect, useState} from "react";
import {useAddOrUpdatePreferencesMutation} from "../reducers/enrollmentApiSlice";
import {Message, MessageType} from "../types/message";
import {ExpandMore} from "@mui/icons-material";
import MessageList from "./MessageList";

export const AccountNotificationSettings = ({ email, preferences, refetch }) => {
    const theme = useTheme();
    const { translate } = useCustomTranslation();

    const [receivesTerminationEmails, setReceivesTerminationEmails] = useState(preferences?.receivesTerminationEmails ?? false);
    const [receivesEnrollmentEmails, setReceivesEnrollmentEmails] = useState(preferences?.receivesEnrollmentEmails ?? false);
    const [messages, setMessages] = useState([]);
    const [addOrUpdatePreferences, { isLoading: isAddingOrUpdatingPreferences }] = useAddOrUpdatePreferencesMutation();

    useEffect(() => {
        if (preferences != null) {
            setReceivesTerminationEmails(preferences?.receivesTerminationEmails ?? false);
            setReceivesEnrollmentEmails(preferences?.receivesEnrollmentEmails ?? false);
        }
    }, [preferences]);

    const handleSubmit = async () => {
        try {
            await addOrUpdatePreferences({
                email: email,
                receivesTerminationEmails: receivesTerminationEmails,
                receivesEnrollmentEmails: receivesEnrollmentEmails
            }).unwrap();
            setMessages([new Message('Saved!', MessageType.Success)]);
            await refetch();
        } catch(error) {
            console.error('Error when saving the account notification preferences:', error);
            setMessages([new Message('Unexpected Error! Please try again.', MessageType.Error)]);
        }
    }

    return (
        <Accordion sx={{ mt: 2, width: '100%' }} defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="edit-account-notifications"
                id="edit-account-notifications-header"
            >
                <Typography variant="h6">
                    {translate('Account Notifications')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>

                <Box sx={{ display: 'flex', flexDirection: 'column', mb: messages > 0 ? 2 : 0}}>
                    <MessageList messages={messages} onClose={() => { setMessages([]); }} />
                </Box>

                <FormControlLabel
                    control={
                        <Switch
                            checked={receivesEnrollmentEmails}
                            onChange={(e) => setReceivesEnrollmentEmails(e.target.checked) }
                            color="primary"
                        />
                    }
                    label={translate('Receive Enrollment Emails')}
                />

                <FormControlLabel
                    control={
                        <Switch
                            checked={receivesTerminationEmails}
                            onChange={(e) => setReceivesTerminationEmails(e.target.checked) }
                            color="primary"
                        />
                    }
                    label={translate('Receive Termination Emails')}
                />

                <Button
                    sx={{ mt: 4 }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isAddingOrUpdatingPreferences}
                    aria-label={translate('Save')}
                    onClick={() => { handleSubmit() }}
                >
                    {isAddingOrUpdatingPreferences ? translate('Saving...') : translate('Save')}
                </Button>
            </AccordionDetails>
        </Accordion>
    );
};